body {
}
.orderFooter {
     position: fixed;
     width: 100vw;
     bottom: 0px;
}
.footerProgess img {
    width: auto;
    max-width: 100%;
    height:70px;

}
.cancelbtn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

    @media screen and (min-width:567px) and (max-width:767px) {
        .loaderDiv {
            position: fixed !important;
            left: 0 !important;
            right: 0px !important;
            text-align: center !important;
            display: flex !important;
            align-items: center;
            justify-content: center;
            top: 0 !important;
            bottom: 0 !important;
            transform: inherit !important;
            flex-direction: column;
        }
        .Thanks {
            margin-bottom: 15px;
        }
        .thanksForm br {
            display: none;
        }
        .thanksForm input {
            margin-bottom: 15px !important;
        }
            
            .fixHeight {
                height: calc(calc(calc(var(--vh, 1vh) * 100) - 95px) - 20px) !important;
            }

        .footerBtn .btn, .orderFooterCard .btn {
            padding: 2px 5px;
            font-size: 14px;
            margin-bottom: 0px !important;
        }

        .orderFooter {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .footerBtn {
            margin-top: 0px !important;
            margin-bottom: 0px !important;
        }

        .footerProgess img, .dispenceFOoter img {
            height: 35px !important;
        }

        .dispenceFOoter img {
            width: auto !important;
        }

        .footerProgess {
            margin: 0px 10px 5px !important;
        }

        .footerBtn {
            margin-top: 0px !important;
            margin-bottom: 5px !important;
        }

        .orderFooterCard {
            bottom: 0px !important;
            padding: 5px;
            background: #fff;
        }

        .circleImg {
            top: 45% !important;
        }

        .touch2start {
            top: 62% !important;
        }

        .allBtn {
            top: 74% !important;
        }

        .bottleImg img {
            width: min(29vw, 13vh) !important;
        }

        .startBtn img {
            width: 135px !important;
        }

        .Toptext {
            font-size: 25px !important;
        }

        .thankyouForm {
            height: auto !important;
        }

        .thanksForm {
            position: relative !important;
            transform: inherit !important;
            left: inherit !important;
            right: inherit !important;
            margin: auto;
            padding: 20px 0px;
        }
    }

    @media screen and (max-width:767px) {
        .cancelbtn button {
            font-size: 14px !important;
        }

        div#ExpiryDate > div, #CreditCardNumber div, #SecurityCode div {
            left: 10px !important;
            top: 9px !important;
        }

        .ml0 {
            margin-left: 0px !important;
        }

        .mr0 {
            margin-right: 0px !important;
        }

        .m0 {
            margin: 0px !important;
        }

        .fixHeight {
            padding: 0px !important;
        }
    }