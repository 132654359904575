.thanksEmail input {
    box-shadow: none !important;
    padding-left: 26px;
    font-size: 23px !important;
    font-family: system-ui;    
}

/*.thankyouForm .form-control {
    border: 1px solid black !important;
    height: calc(1.5em + 0.75rem + 11px) !important;
}*/

.thanksForm .onvalidEmail {
    border: 1px solid black !important;
    height: calc(1.5em + 0.75rem + 11px) !important;
}

.thanksForm .oninvalidEmail {
    border: 1px solid red !important;
    height: calc(1.5em + 0.75rem + 11px) !important;
}

.Thanks .btn {
    border: 1px solid black;
    border-radius: 10px;
}

.receiptsuccess {
    color: green;
}

.receiptdanger {
    color: red;
}

.thanksForm {
    width: 75%;
    text-align: center; 
    position: absolute; 
    left: 50%; 
    top: 50%; 
    transform: translate(-50%, -50%); 
    font-family: "Gotham-Medium";
}

@media screen and (max-width: 480px) {
    .thankyou {
        font-size: 3rem;
    }
    .thanksForm {
        width: 90%;
        text-align: center;
        position: absolute;
        left: 50%;
        top: 40%;
        transform: translate(-50%, -50%);
        font-family: "Gotham-Medium";

    }
}
@media only screen and (min-width: 601px) {
    .thankyou {
        font-size: 59px;
    }
    .thanksForm {
        width: 30%;
        text-align: center;
        position: absolute;
        left: 50%;
        top: 40%;
        transform: translate(-50%, -50%);
        font-family: "Gotham-Medium";
    }
}
@media only screen and (min-width: 701px) {
    .thankyou {
        font-size: 45px;
    }
}